@import "../../scss";

.resultList {
  max-width: 380px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.resultBox {
  width: 100%;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 14px;
  padding: 30px 14px;
  background: white;
  .finalResult {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 5px;
    .finalResultText {
      font-size: 1.25rem;
      text-align: center;
    }
  }
  .resultBox_resultText {
    margin-bottom: 20px;
    color: $nord2;
    font-size: 1rem;
    font-weight: 500;
    display: flex;
    flex-direction: column;
    gap: 14px;
    width: 100%;
    padding: 0 20px;
  }
}

.recommendationBox {
  width: 100%;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 14px;
  padding: 20px 14px;
  background: white;
  .recommendationBox_title {
    font-size: 1.25rem;
    color: $x-nord1;
    font-weight: 400;
    text-align: center;
  }
}

.item {
  display: flex;
  width: 100%;
  justify-content: space-between;
  .key {
    color: $x-nord1;
    font-size: 1.1rem;
    font-weight: 400;
  }
  .value {
    color: $nord2;
    font-size: 1.1rem;
  }
}

.resultBox_title {
  font-size: 1.25rem;
  color: $x-nord1;
  font-weight: 400;
}

.resultBox_title2 {
  font-size: 0.95rem;
  color: $x-nord1;
  font-weight: 400;
}

.non_rec_text {
  font-size: 0.95rem;
  color: $x-nord1;
  text-align: center;
}

.red {
  color: $nord11 !important;
}

.green {
  color: $nord14 !important;
}

.grey {
  color: $x-nord1 !important;
}