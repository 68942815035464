@import "../../scss";

.applicationLayout {
  max-height: 100vh;
  width: 100vw;
  overflow-y: auto;
  position: relative;
  scrollbar-gutter: stable both-edges;
}

.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.container {
  max-width: 1520px;
  padding: 40px 20px;
  margin: 0 auto;
  display: flex;
  gap: 20px;
}

.navbar {
  min-width: 260px;
  position: sticky;
  height: min-content;
  top: 40px;
}

.content {
  flex-grow: 1;
}
