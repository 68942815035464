@import "../../../scss";

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  width: 100%;
  height: 100vh;
  padding: 100px 0;
  background: rgba(#000, 0.6);
  overflow-y: auto;
  backdrop-filter: blur(1px);
  &.modalEnter {
    opacity: 0;
    .modalWrap {
      transform: scale(0.9) translateY(50px);
    }
  }
  &.modalEnterActive {
    transition: 150ms ease-in-out;
    opacity: 1;
    .modalWrap {
      transition: 150ms ease-in-out;
      transform: scale(1) translateY(0);
    }
  }
  &.modalExit {
    opacity: 1;
    .modalWrap {
      transform: scale(1) translateY(0);
    }
  }
  &.modalExitActive {
    transition: 150ms ease-in-out;
    opacity: 0;
    .modalWrap {
      transition: 150ms ease-in-out;
      transform: scale(0.9) translateY(50px);
    }
  }
  .modalWrap {
    margin: 0 auto;
    background: white;
    border-radius: 8px;
    width: 380px;
    padding: 24px 24px;
    display: flex;
    flex-direction: column;
    gap: 30px;
    .title {
      font-size: 1.25rem;
      font-weight: 600;
      color: $nord1;
    }
    .inputs {
      display: flex;
      flex-direction: column;
      gap: 18px;
      width: 100%;
      .input {
        display: flex;
        flex-direction: column;
        gap: 5px;
      }
      .inputError {
        color: $nord11;
        font-size: 0.875rem;
      }
    }
    .buttonGroup {
      display: flex;
      justify-content: flex-end;
      gap: 14px;
    }
  }
}
