@import "../../scss";

$columns: 1fr 1.5fr 1fr 1fr 130px 160px;

.results {
  overflow-y: auto;
  width: 100%;
  min-height: 100vh;
  scrollbar-gutter: stable both-edges;
}

.search {
  margin-top: 20px;
}

.container {
  max-width: 1520px;
  padding: 40px 20px 120px 20px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.tools {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  gap: 20px;
  flex-wrap: wrap;
  background: white;
  border-radius: 4px;
  padding: 14px;
  border: 1px solid $nord4;
}

.filterBox {
  max-width: fit-content;
  display: flex;
  gap: 20px;
  align-items: flex-end;
  .input {
    min-width: 200px;
    max-width: 350px;
  }
}

.tableIsEmptyInfo {
  margin-top: 4px;
  padding: 18px;
  border-radius: 10px;
  font-size: 1.125rem;
  color: darken($nord4, 24%);
  background: darken($nord5, 2%);
}

.tableLoading {
  margin-top: 4px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  .tableLoadingRow {
    background: $x-nord1;
    border-radius: 4px;
    height: 68px;
    opacity: 0.4;
    padding: 20px 10px 0 10px;
  }
}

.buttonGroup {
  display: flex;
  align-items: center;
  gap: 14px;
  height: 42px;
}

.table {
  display: flex;
  flex-direction: column;
  .tableRowWrap {
    padding: 3px 0;
    user-select: none;
  }
  .tableRow {
    background: white;
    border-radius: 4px;
    padding: 0 10px;
    height: 68px;
    display: grid;
    grid-template-columns: $columns;
    transition: 80ms ease-in-out;
    border: 1px solid $nord4;
  }
  .item {
    color: $x-nord1;
    width: 100%;
    height: 100%;
    padding: 0 10px;
    font-size: 0.875rem;
    font-weight: 500;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    gap: 3px;
    .span {
      display: flex;
      align-items: center;
      color: $x-nord2;
      font-size: 0.875rem;
      font-weight: 400;
    }
  }
}

.pagination {
  display: flex;
  align-items: center;
  gap: 14px;
  height: 46px;
  .arrow,
  .label {
    background: white;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 11px;
    border-radius: 8px;
    transition: 80ms linear;
    color: $x-nord1;
    border: 1px solid transparent;
    &.disabled {
      opacity: 0.5;
      pointer-events: none;
    }
  }
  .arrow {
    cursor: pointer;
    &:hover {
      border-color: lighten($x-nord1, 10%);
    }
  }
}

.red {
  color: $nord11 !important;
}

.green {
  color: $nord14 !important;
}
